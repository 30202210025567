import React from 'react';
import "./App.css";
import WebBG from './imageset/WebBG'
import PI from "./components/ProgressiveImage";
import Header from "./components/Header";
import Welcome from "./components/Welcome";
import Ethos from "./components/Ethos";
import Mint from "./components/Mint";
import Roadmap from "./components/Roadmap";
import Team from "./components/Team";
import Faq from "./components/Faq";
// PLOPINJECTIMPORT

function App () {
  const [width, setWidth] = React.useState(
    document.documentElement.clientWidth
  );
  const [portrait, setPortrait] = React.useState(true);
  const refs = [
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    // PLOPINJECTREF
  ];

  React.useLayoutEffect(() => {
    setWidth(document.documentElement.clientWidth);
    setPortrait(
      window.matchMedia('(min-aspect-ratio: 1/1)').matches
    );
    window.addEventListener("resize", () => {
      setWidth(document.documentElement.clientWidth);
      setPortrait(
        window.matchMedia('(min-aspect-ratio: 1/1)').matches
      );
    });
  }, []);

  return (
    <PI imageSet={WebBG} width={width}>
      {
        (src) => (<div className="App">
          <div style={{
            backgroundImage: `url(${src})`, backgroundSize: 'cover', backgroundPosition: 'center', width: '100vw', height: '100vh', position: 'fixed', zIndex: 0, top: 0, left: 0, imageRendering: "pixelated",
          }} />
          <div ref={refs[0]} style={{ position: "relative", zIndex: 1 }}><Header width={width} portrait={portrait} /></div>
          <div ref={refs[1]} style={{ position: "relative", zIndex: 1 }}><Welcome width={width} portrait={portrait} /></div>
          <div ref={refs[2]} style={{ position: "relative", zIndex: 1 }}><Ethos width={width} portrait={portrait} /></div>
          <div ref={refs[3]} style={{ position: "relative", zIndex: 2 }}><Mint width={width} portrait={portrait} /></div>
          <div ref={refs[4]} style={{ position: "relative", zIndex: 1 }}><Roadmap width={width} portrait={portrait} /></div>
          <div ref={refs[5]} style={{ position: "relative", zIndex: 2 }}><Team width={width} portrait={portrait} /></div>
          <div ref={refs[6]} style={{ position: "relative", zIndex: 1 }}><Faq width={width} portrait={portrait} /></div>
          {/* PLOPINJECTDOM */}
        </div>)
      }
    </PI>

  );
}

export default App;
