import PropTypes from "prop-types";
import React from "react";
import PI from "../components/ProgressiveImage";
import WebWelcome from "../imageset/WebWelcome";
import MobileWelcome from "../imageset/MobileWelcome";
import JoinButton from "../assets/JoinButton.png";
import MintButton from "../assets/MintButton.png";
import JoinMobile from "../assets/JoinMobile.png";
import MintMobile from "../assets/MintMobile.png";

class Welcome extends React.Component {
  render() {
    if (this.props.portrait)
      return (
        <PI imageSet={WebWelcome} width={this.props.width}>
          {(src) => (
            <div
              style={{
                width: this.props.width,
                height: (this.props.width / 1440) * 3406,
                backgroundImage: `url(${src})`,
                backgroundSize: `cover`,
                backgroundPosition: "center",
                // imageRendering: "pixelated",
                position: "relative",
              }}
            >
              <div
                className="button-sprite"
                style={{
                  width: (this.props.width / 1440) * 426,
                  height: (this.props.width / 1440) * 117,
                  backgroundImage: `url(${MintButton})`,
                  left: (this.props.width / 1440) * 507,
                  top: (this.props.width / 1440) * 2122,
                }}
                onClick={() => window.open("https://pikozoo.op3n.world/mint")}
              ></div>
              <div
                className="button-sprite"
                style={{
                  width: (this.props.width / 1440) * 426,
                  height: (this.props.width / 1440) * 117,
                  backgroundImage: `url(${JoinButton})`,
                  left: (this.props.width / 1440) * 507,
                  top: (this.props.width / 1440) * 2251.47,
                }}
                onClick={() =>
                  window.open("https://op3n.world/communities/pikozoo")
                }
              ></div>
            </div>
          )}
        </PI>
      );
    else
      return (
        <PI imageSet={MobileWelcome} width={this.props.width}>
          {(src) => (
            <div
              style={{
                width: this.props.width,
                height: (this.props.width / 428) * 2468,
                backgroundImage: `url(${src})`,
                backgroundSize: `cover`,
                backgroundPosition: "center",
                // imageRendering: "pixelated",
                position: "relative",
              }}
            >
              <div
                className="button-sprite"
                style={{
                  width: (this.props.width / 428) * 237,
                  height: (this.props.width / 428) * 65,
                  backgroundImage: `url(${MintMobile})`,
                  left: (this.props.width / 428) * 97,
                  top: (this.props.width / 428) * 1514,
                }}
                onClick={() => window.open("https://pikozoo.op3n.world/mint")}
              ></div>
              <div
                className="button-sprite"
                style={{
                  width: (this.props.width / 428) * 237,
                  height: (this.props.width / 428) * 65,
                  backgroundImage: `url(${JoinMobile})`,
                  left: (this.props.width / 428) * 97,
                  top: (this.props.width / 428) * 1599,
                }}
                onClick={() =>
                  window.open("https://op3n.world/communities/pikozoo")
                }
              ></div>
            </div>
          )}
        </PI>
      );
  }
}

Welcome.propTypes = {
  width: PropTypes.number,
};

Welcome.defaultProps = {
  width: 1440,
};

export default Welcome;
