import PropTypes from "prop-types";
import React from "react";
import PI from "../components/ProgressiveImage";
import WebRoadmap from "../imageset/WebRoadmap";
import MobileRoadmap from "../imageset/MobileRoadmap";

class Roadmap extends React.Component {
  render() {
    if (this.props.portrait)
      return (
        <PI imageSet={WebRoadmap} width={this.props.width}>
          {(src) => (
            <>
              <div
                style={{
                  width: this.props.width,
                  height: (this.props.width / 1440) * 1538,
                  backgroundImage: `url(${src})`,
                  backgroundSize: `cover`,
                  backgroundPosition: "center",
                  // imageRendering: "pixelated",
                  // position: "relative",
                  // top: -((this.props.width / 1440) * 61),
                }}
              ></div>
            </>
          )}
        </PI>
      );
    else
      return (
        <PI imageSet={MobileRoadmap} width={this.props.width}>
          {(src) => (
            <>
              <div
                style={{
                  width: this.props.width,
                  height: (this.props.width / 428) * 1619,
                  backgroundImage: `url(${src})`,
                  backgroundSize: `cover`,
                  backgroundPosition: "center",
                  // imageRendering: "pixelated",
                  // position: "relative",
                  // top: -((this.props.width / 1440) * 61),
                }}
              ></div>
            </>
          )}
        </PI>
      );
  }
}

Roadmap.propTypes = {
  width: PropTypes.number,
};

Roadmap.defaultProps = {
  width: 1440,
};

export default Roadmap;
