import PropTypes from "prop-types";
import React from "react";
import PI from "../components/ProgressiveImage";
import WebHeader from "../imageset/WebHeader";
import WebHeaderOverlay from "../imageset/WebHeaderOverlay";
import MobileHeader from "../imageset/MobileHeader";
import MobileHeaderOverlay from "../imageset/MobileHeaderOverlay";
import WebVideo from "../video/Zombie Zoo Piko Ver Trailer - 16-9.h264.mp4";
import MobileVideo from "../video/Zombie Zoo Piko Ver Trailer - Vertical.h264.mp4";

class Header extends React.Component {
  render() {
    if (this.props.portrait)
      return (
        <PI imageSet={WebHeader} width={this.props.width}>
          {(src) => (
            <div
              style={{
                width: this.props.width,
                height: (this.props.width / 2880) * 1997,
                backgroundImage: `url(${src})`,
                backgroundSize: `${
                  Math.max(
                    window.screen.width / 2880,
                    window.screen.height / 1997
                  ) * 2880
                }px ${
                  Math.max(
                    window.screen.width / 2880,
                    window.screen.height / 1997
                  ) * 1997
                }px`,
                backgroundPosition: "center",
                imageRendering: "pixelated",
                position: "relative",
              }}
            >
              <video
                src={WebVideo}
                loop
                muted
                autoPlay
                playsInline
                style={{
                  position: "absolute",
                  width: (this.props.width / 1440) * 1206,
                  height: (this.props.width / 1440) * 678,
                  left: (this.props.width / 1440) * 117,
                  top: (this.props.width / 1440) * 151.69,
                  border: "5px solid #6E67DE",
                  boxSizing: "border-box",
                  objectFit: "cover",
                }}
              ></video>
              <PI imageSet={WebHeaderOverlay} width={this.props.width}>
                {(src) => (
                  <div
                    style={{
                      width: (1384 / 1440) * this.props.width,
                      height: (930.75 / 1440) * this.props.width,
                      position: "absolute",
                      left: (27 / 1440) * this.props.width,
                      top: (53 / 1440) * this.props.width,
                      backgroundImage: `url(${src})`,
                      backgroundSize: `contain`,
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                      imageRendering: "pixelated",
                    }}
                  ></div>
                )}
              </PI>
            </div>
          )}
        </PI>
      );
    else
      return (
        <PI imageSet={MobileHeader} width={this.props.width}>
          {(src) => (
            <div
              style={{
                width: this.props.width,
                height: (this.props.width / 428) * 940,
                backgroundImage: `url(${src})`,
                backgroundSize: `cover`,
                backgroundPosition: "center",
                imageRendering: "pixelated",
                position: "relative",
              }}
            >
              <video
                src={MobileVideo}
                loop
                muted
                autoPlay
                playsInline
                style={{
                  position: "absolute",
                  width: (this.props.width / 428) * 347,
                  height: (this.props.width / 428) * 666,
                  left: (this.props.width / 428) * 42,
                  top: (this.props.width / 428) * 127,
                  border: "3px solid #6E67DE",
                  boxSizing: "border-box",
                  objectFit: "cover",
                }}
              ></video>
              <PI imageSet={MobileHeaderOverlay} width={this.props.width}>
                {(src) => (
                  <div
                    style={{
                      width: (426 / 428) * this.props.width,
                      height: (859 / 428) * this.props.width,
                      position: "absolute",
                      left: (1 / 428) * this.props.width,
                      top: (57 / 428) * this.props.width,
                      backgroundImage: `url(${src})`,
                      backgroundSize: `contain`,
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                      imageRendering: "pixelated",
                    }}
                  ></div>
                )}
              </PI>
            </div>
          )}
        </PI>
      );
  }
}

Header.propTypes = {
  width: PropTypes.number,
  portrait: PropTypes.bool,
};

Header.defaultProps = {
  width: 1440,
  portrait: true,
};

export default Header;
