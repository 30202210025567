import PropTypes from "prop-types";
import React from "react";
import PI from "../components/ProgressiveImage";
import WebMint from "../imageset/WebMint";
import MobileMint from "../imageset/MobileMint";
import JoinButton from "../assets/JoinButtonPink.png";
import JoinMobile from "../assets/JoinMobilePink.png";

class Mint extends React.Component {
  render() {
    if (this.props.portrait)
      return (
        <PI imageSet={WebMint} width={this.props.width}>
          {(src) => (
            <>
              <div
                style={{
                  width: this.props.width,
                  height: (this.props.width / 1440) * 1594,
                  backgroundImage: `url(${src})`,
                  backgroundSize: `cover`,
                  backgroundPosition: "center",
                  // imageRendering: "pixelated",
                  position: "absolute",
                }}
              ></div>
              <div
                style={{
                  width: this.props.width,
                  height: (this.props.width / 1440) * 1531,
                }}
              >
                <div
                  className="button-sprite"
                  style={{
                    width: (this.props.width / 1440) * 360,
                    height: (this.props.width / 1440) * 99,
                    backgroundImage: `url(${JoinButton})`,
                    left: (this.props.width / 1440) * 535,
                    top: (this.props.width / 1440) * 852,
                  }}
                  onClick={() => window.open("https://op3n.world/communities/pikozoo")}
                ></div>
              </div>
            </>
          )}
        </PI>
      );
    else
      return (
        <PI imageSet={MobileMint} width={this.props.width}>
          {(src) => (
            <>
              <div
                style={{
                  width: this.props.width,
                  height: (this.props.width / 428) * 1069,
                  backgroundImage: `url(${src})`,
                  backgroundSize: `cover`,
                  backgroundPosition: "center",
                  // imageRendering: "pixelated",
                  position: "absolute",
                }}
              ></div>
              <div
                style={{
                  width: this.props.width,
                  height: (this.props.width / 428) * 996,
                }}
              >
                <div
                  className="button-sprite"
                  style={{
                    width: (this.props.width / 428) * 237,
                    height: (this.props.width / 428) * 65,
                    backgroundImage: `url(${JoinMobile})`,
                    left: (this.props.width / 428) * 90,
                    top: (this.props.width / 428) * 560,
                  }}
                  onClick={() => window.open("https://op3n.world/communities/pikozoo")}
                ></div>
              </div>
            </>
          )}
        </PI>
      );
  }
}

Mint.propTypes = {
  width: PropTypes.number,
};

Mint.defaultProps = {
  width: 1440,
};

export default Mint;
