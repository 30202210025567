import PropTypes from "prop-types";
import React from "react";
import PI from "../components/ProgressiveImage";
import WebTeam from "../imageset/WebTeam";
import MobileTeam from "../imageset/MobileTeam";
import WebTeamOverlay from "../imageset/WebTeamOverlay";

class Team extends React.Component {
  render() {
    if (this.props.portrait)
      return (
        <PI imageSet={WebTeam} width={this.props.width}>
          {(src) => (
            <>
              <div
                style={{
                  width: this.props.width,
                  height: (this.props.width / 1440) * 987,
                  backgroundImage: `url(${src})`,
                  backgroundSize: `${
                    Math.max(
                      window.screen.width / 1440,
                      window.screen.height / 987
                    ) * 1440
                  }px ${
                    Math.max(
                      window.screen.width / 1440,
                      window.screen.height / 987
                    ) * 987
                  }px`,
                  backgroundPosition: "center",
                  imageRendering: "pixelated",
                  position: "relative",
                }}
              ></div>
              <PI imageSet={WebTeamOverlay} width={this.props.width}>
                {(src) => (
                  <div
                    style={{
                      width: this.props.width,
                      height: (this.props.width / 1440) * 1134,
                      backgroundImage: `url(${src})`,
                      backgroundSize: `cover`,
                      backgroundPosition: "center",
                      // imageRendering: "pixelated",
                      position: "absolute",
                      top: -((this.props.width / 1440) * 101),
                    }}
                  >
                    <div
                      style={{
                        width: (this.props.width / 1440) * 250,
                        height: (this.props.width / 1440) * 250,
                        position: "absolute",
                        borderRadius: "50%",
                        left: (this.props.width / 1440) * 97,
                        top: (this.props.width / 1440) * 382,
                      }}
                      onClick={() =>
                        window.open("https://twitter.com/pikotaro_ppap")
                      }
                    ></div>
                    <div
                      style={{
                        width: (this.props.width / 1440) * 250,
                        height: (this.props.width / 1440) * 250,
                        position: "absolute",
                        borderRadius: "50%",
                        left: (this.props.width / 1440) * 427,
                        top: (this.props.width / 1440) * 489,
                      }}
                      onClick={() =>
                        window.open("https://twitter.com/zombiezooart")
                      }
                    ></div>
                    <div
                      style={{
                        width: (this.props.width / 1440) * 250,
                        height: (this.props.width / 1440) * 250,
                        position: "absolute",
                        borderRadius: "50%",
                        left: (this.props.width / 1440) * 757,
                        top: (this.props.width / 1440) * 382,
                      }}
                      onClick={() =>
                        window.open("https://twitter.com/emikusano")
                      }
                    ></div>
                    <div
                      style={{
                        width: (this.props.width / 1440) * 250,
                        height: (this.props.width / 1440) * 250,
                        position: "absolute",
                        borderRadius: "50%",
                        left: (this.props.width / 1440) * 1087,
                        top: (this.props.width / 1440) * 489,
                      }}
                      onClick={() => window.open("https://twitter.com/wengie")}
                    ></div>
                  </div>
                )}
              </PI>
            </>
          )}
        </PI>
      );
    else
      return (
        <PI imageSet={MobileTeam} width={this.props.width}>
          {(src) => (
            <>
              <div
                style={{
                  width: this.props.width,
                  height: (this.props.width / 428) * 967,
                  backgroundImage: `url(${src})`,
                  backgroundSize: `cover`,
                  backgroundPosition: "center",
                  imageRendering: "pixelated",
                  position: "absolute",
                }}
              ></div>
              <div
                style={{
                  width: this.props.width,
                  height: (this.props.width / 428) * 945,
                }}
              >
                <div
                  style={{
                    width: (this.props.width / 428) * 190,
                    height: (this.props.width / 428) * 190,
                    position: "absolute",
                    borderRadius: "50%",
                    left: (this.props.width / 428) * 30,
                    top: (this.props.width / 428) * 147,
                  }}
                  onClick={() =>
                    window.open("https://twitter.com/pikotaro_ppap")
                  }
                ></div>
                <div
                  style={{
                    width: (this.props.width / 428) * 190,
                    height: (this.props.width / 428) * 190,
                    position: "absolute",
                    borderRadius: "50%",
                    left: (this.props.width / 428) * 205,
                    top: (this.props.width / 428) * 297,
                  }}
                  onClick={() =>
                    window.open("https://twitter.com/zombiezooart")
                  }
                ></div>
                <div
                  style={{
                    width: (this.props.width / 428) * 190,
                    height: (this.props.width / 428) * 190,
                    position: "absolute",
                    borderRadius: "50%",
                    left: (this.props.width / 428) * 30,
                    top: (this.props.width / 428) * 450,
                  }}
                  onClick={() => window.open("https://twitter.com/emikusano")}
                ></div>
                <div
                  style={{
                    width: (this.props.width / 428) * 190,
                    height: (this.props.width / 428) * 190,
                    position: "absolute",
                    borderRadius: "50%",
                    left: (this.props.width / 428) * 205,
                    top: (this.props.width / 428) * 603,
                  }}
                  onClick={() => window.open("https://twitter.com/wengie")}
                ></div>
              </div>
            </>
          )}
        </PI>
      );
  }
}

Team.propTypes = {
  width: PropTypes.number,
};

Team.defaultProps = {
  width: 1440,
};

export default Team;
