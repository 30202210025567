import PropTypes from "prop-types";
import React from "react";
import PI from "../components/ProgressiveImage";
import WebFaq from "../imageset/WebFaq";
import MobileFaq from "../imageset/MobileFaq";
import WebFaqOverlay from "../imageset/WebFaqOverlay";
import terms from "../assets/terms.png";

class Faq extends React.Component {
  render() {
    if (this.props.portrait)
      return (
        <PI imageSet={WebFaq} width={this.props.width}>
          {(src) => (
            <>
              <div
                style={{
                  width: this.props.width,
                  height: (this.props.width / 1440) * 1744,
                  backgroundImage: `url(${src})`,
                  backgroundSize: `${
                    Math.max(
                      window.screen.width / 1440,
                      window.screen.height / 1744
                    ) * 1440
                  }px ${
                    Math.max(
                      window.screen.width / 1440,
                      window.screen.height / 1744
                    ) * 1744
                  }px`,
                  backgroundPosition: "center",
                  // imageRendering: "pixelated",
                  // position: "relative",
                  // top: -((this.props.width / 1440) * 61),
                }}
              ></div>
              <PI imageSet={WebFaqOverlay} width={this.props.width}>
                {(src) => (
                  <div
                    style={{
                      width: this.props.width,
                      height: (this.props.width / 1440) * 1744,
                      backgroundImage: `url(${src})`,
                      backgroundSize: `cover`,
                      backgroundPosition: "center",
                      // imageRendering: "pixelated",
                      position: "absolute",
                      left: 0,
                      top: (this.props.width / 1440) * 0,
                    }}
                  >
                    <div
                      style={{
                        width: (this.props.width / 1440) * 147,
                        height: (this.props.width / 1440) * 27,
                        backgroundImage: `url(${terms})`,
                        backgroundSize: `cover`,
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                        // imageRendering: "pixelated",
                        position: "absolute",
                        bottom: (this.props.width / 1440) * 37,
                        left: (this.props.width / 1440) * 670,
                      }}
                      onClick={() =>
                        window.open("https://support.op3n.world/terms-of-use")
                      }
                    ></div>
                  </div>
                )}
              </PI>
            </>
          )}
        </PI>
      );
    else
      return (
        <PI imageSet={MobileFaq} width={this.props.width}>
          {(src) => (
            <>
              <div
                style={{
                  width: this.props.width,
                  height: (this.props.width / 428) * 1025,
                  backgroundImage: `url(${src})`,
                  backgroundSize: `cover`,
                  backgroundPosition: "center",
                  // imageRendering: "pixelated",
                  // position: "relative",
                  // top: -((this.props.width / 1440) * 61),
                }}
              >
                <div
                  style={{
                    width: (this.props.width / 428) * 147,
                    height: (this.props.width / 428) * 27,
                    backgroundImage: `url(${terms})`,
                    backgroundSize: `cover`,
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    // imageRendering: "pixelated",
                    position: "absolute",
                    bottom: (this.props.width / 428) * 85,
                    left: (this.props.width / 428) * 139,
                  }}
                  onClick={() =>
                    window.open("https://support.op3n.world/terms-of-use")
                  }
                ></div>
              </div>
            </>
          )}
        </PI>
      );
  }
}

Faq.propTypes = {
  width: PropTypes.number,
};

Faq.defaultProps = {
  width: 1440,
};

export default Faq;
