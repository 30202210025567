import PropTypes from "prop-types";
import React from "react";
import PI from "../components/ProgressiveImage";
import WebEthos from "../imageset/WebEthos";
import MobileEthos from "../imageset/MobileEthos";

class Ethos extends React.Component {
  render() {
    if (this.props.portrait)
      return (
        <PI imageSet={WebEthos} width={this.props.width}>
          {(src) => (
            <>
              <div
                style={{
                  width: this.props.width,
                  height: (this.props.width / 1440) * 2233,
                  backgroundImage: `url(${src})`,
                  backgroundSize: `cover`,
                  backgroundPosition: "center",
                  // imageRendering: "pixelated",
                  position: "absolute",
                  top: -((this.props.width / 1440) * 61),
                }}
              ></div>
              <div
                style={{
                  width: this.props.width,
                  height: (this.props.width / 1440) * 2172,
                }}
              ></div>
            </>
          )}
        </PI>
      );
    else
      return (
        <PI imageSet={MobileEthos} width={this.props.width}>
          {(src) => (
            <>
              <div
                style={{
                  width: this.props.width,
                  height: (this.props.width / 428) * 2287,
                  backgroundImage: `url(${src})`,
                  backgroundSize: `cover`,
                  backgroundPosition: "center",
                  // imageRendering: "pixelated",
                  // position: "absolute",
                  // top: -((this.props.width / 428) * 61),
                  marginTop: (this.props.width / 428) * 220,
                }}
              ></div>
            </>
          )}
        </PI>
      );
  }
}

Ethos.propTypes = {
  width: PropTypes.number,
};

Ethos.defaultProps = {
  width: 1440,
};

export default Ethos;
