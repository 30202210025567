import PropTypes from "prop-types";
import React from "react";

class ProgressiveImage extends React.Component {
  images = [];

  constructor(props) {
    super(props);
    this.state = {
      image: null,
      width: 0,
    };
  }

  componentDidMount() {
    const { imageSet } = this.props;
    this.loadImages(imageSet);
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { imageSet } = nextProps;
    this.loadImages(imageSet);

    if (nextProps !== this.props && nextState === this.state) {
      this.setState({ width: 0 });
    }
    return true;
  }

  componentWillUnmount() {
    if (this.images) {
      this.images.forEach((image) => {
        image.onload = null;
        image.onerror = null;
        image.src = "";
      });
    }
  }

  loadImages = (imageSet) => {
    // If there is already an image we nullify the onload
    // and onerror props so it does not incorrectly set state
    // when it resolves
    if (this.images) {
      this.images.forEach((image) => {
        image.onload = null;
        image.onerror = null;
        image.src = "";
      });
      this.images = [];
    }
    Object.entries(imageSet).forEach(([width, src]) => {
      width = parseInt(width);
      if (width >= this.props.width * devicePixelRatio * 1.5) {
        return;
      }
      const image = new Image();
      image.dataset.width = width;
      this.image = image;
      image.onload = this.onLoad.bind(this, image);
      image.onerror = this.onError.bind(this, image);
      image.src = src;
      this.images.push(image);
    });
  };

  onLoad = (image) => {
    // use this.image.src instead of this.props.src to
    // avoid the possibility of props being updated and the
    // new image loading before the new props are available as
    // this.props.
    if (
      !this.state.image ||
      parseInt(image.dataset.width) > parseInt(this.state.width)
    ) {
      this.setImage(image);
    }
  };

  setImage = (image) => {
    this.setState({
      image: image.src,
      width: parseInt(image.dataset.width),
    });
  };

  onError = (image, errorEvent) => {
    const { onError } = this.props;
    if (onError) {
      onError(errorEvent, image);
    }
  };

  render() {
    const { image } = this.state;
    const { children } = this.props;

    if (!children || typeof children !== "function") {
      throw new Error(`ProgressiveImage requires a function as its only child`);
    }

    return children(image);
  }
}

ProgressiveImage.propTypes = {
  width: PropTypes.number,
  imageSet: PropTypes.object,
  children: PropTypes.func,
  onError: PropTypes.func,
};

ProgressiveImage.defaultProps = {
  width: 1440,
  imageSet: {},
  children: () => {},
};

export default ProgressiveImage;
